import { type CellPosition } from "./utils/profile";
import type { GetGLPGSFuncsType } from "./utils/funcs";
import { AdytonEdge } from "./api/adyton-edge";
import { AdytonEdgeStub } from "./api/stub/adyton-edge";
import { getUUID } from "./utils";
import * as process from "process";

interface FunctionResult {
  abort: boolean;
  values?: string[];
}

type SyncFunctionPayload = {
  type: "sync";
  result: FunctionResult;
  cellPosition: CellPosition;
};

type BatchFunctionPayload = {
  type: "batch";
  paths: string[];
  timeout: number;
  cellPosition: CellPosition;
};

export type FunctionPayload = SyncFunctionPayload | BatchFunctionPayload;

export async function sysExit(func: GetGLPGSFuncsType, params: string[]): Promise<SyncFunctionPayload> {
  const EXIT_WORDS = [
    "0",
    "止める",
    "stop",
    "exit",
    "終了",
    "中断",
    "やめる",
    "終わり",
    "終わる",
    "終了する",
    "中止",
    "終わります",
  ];
  const abort = params.length > 0 && EXIT_WORDS.includes(params[0]);

  return {
    type: "sync",
    result: {
      abort,
      values: abort ? ["stop"] : ["skip"],
    },
    cellPosition: { row: func.row, column: func.column + 1 },
  };
}

type BatchFunction = (func: GetGLPGSFuncsType, name: string, params: string[]) => Promise<BatchFunctionPayload>;

export function buildBatchFunction(client: AdytonEdge | AdytonEdgeStub): BatchFunction {
  async function batch(func: GetGLPGSFuncsType, name: string, params: string[]): Promise<BatchFunctionPayload> {
    const resTaskExecution = await client.taskExecutions({
      tasks: [
        {
          id: getUUID(),
          function: name,
          params: params,
        },
      ],
      app_service: process.env["APP_SERVICE"],
    });

    return {
      type: "batch",
      paths: resTaskExecution.executions.map((execution) => execution.path),
      timeout: Math.max(...resTaskExecution.executions.map((execution) => execution.timeout)) * 1000,
      cellPosition: { row: func.row, column: func.column + 1 },
    };
  }

  return batch;
}

type FetchBatchResult = (paths: string[], timeout: number) => Promise<FunctionResult>;

type Poller = <Result>(fetch: () => Promise<Result | undefined>, maxRemainsMx: number) => Promise<Result | undefined>;

export function buildFetchBatchResult(poller: Poller, client: AdytonEdge | AdytonEdgeStub): FetchBatchResult {
  async function fetch(paths: string[], timeout: number): Promise<FunctionResult> {
    const result = await poller(async () => {
      const result = await client.tasksResultsFetch({ paths });
      if (result.results.every((result) => result.is_finished)) {
        return result.results[0].content?.map((content) => content.text);
      }

      return undefined;
    }, timeout);
    if (result === undefined) {
      return {
        abort: true,
      };
    }

    return {
      abort: false,
      values: result,
    };
  }

  return fetch;
}
