export class StateManager {
  private stateKey = "AISheetsState";
  async getState(): Promise<string> {
    return await OfficeRuntime.storage.getItem(this.stateKey);
  }

  async saveState(state: string) {
    await OfficeRuntime.storage.setItem(this.stateKey, state);
  }

  async deleteState() {
    await OfficeRuntime.storage.removeItem(this.stateKey);
  }
}
