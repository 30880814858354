import retry from "async-retry";
import { resolver } from "../utils";

export interface TaskExecutionsParams {
  tasks: TaskParams[];
  app_service: string;
}

export interface TaskParams {
  id: string;
  function: string;
  params: string[];
}

export interface TaskExecutionsMeta {
  runtime: "excel";
  excel: {
    environment: "local" | "onedrive";
    path: string;
  };
}

export interface TaskExecutionsResponse {
  executions: {
    id: string;
    path: string;
    timeout: number;
  }[];
}

export interface TasksResultsFetchParams {
  paths: string[];
}

export interface TasksResultsFetchResponse {
  results: {
    path: string;
    is_finished: boolean;
    content?: ContentText[];
  }[];
}
interface ContentText {
  type: string;
  text: string;
}

export class AdytonEdge {
  private readonly domain: string;
  private readonly contentType: string;
  private readonly errorMessage: string;
  private readonly email: string;
  private readonly password: string;
  private readonly meta: TaskExecutionsMeta;

  constructor(domain: string, email: string, password: string, meta: TaskExecutionsMeta) {
    this.domain = domain;
    this.contentType = "application/json";
    this.errorMessage = "AIサーバへの接続に失敗しました.\n再実行お願いします.";
    this.email = email;
    this.password = password;
    this.meta = meta;
  }

  async taskExecutions(data: TaskExecutionsParams): Promise<TaskExecutionsResponse> {
    const method = "POST";
    const url = `${this.domain}/api/v1/tasks/executions`;

    const response = await retry(
      async (bail) => {
        const response = await fetch(url, {
          method,
          headers: { "Content-Type": this.contentType },
          body: JSON.stringify({ ...data, email: this.email, password: this.password, meta: this.meta }),
        });
        if (!response.ok && 500 <= response.status) {
          const errMessage = await response.text();
          console.error(errMessage);
          throw new Error(this.errorMessage);
        } else if (!response.ok && 400 <= response.status) {
          const errMessageJson = await response.json();
          console.error(errMessageJson);
          const errMessage = errMessageJson.message ? errMessageJson.message : errMessageJson.detail;
          throw new Error(errMessage);
        }

        return await response.json();
      },
      {
        retries: Number(process.env["RESOLVE_RETRY_MAX_TIMES"]),
        onRetry: async (err: Error, num: number) => await resolver(err, num),
      }
    );
    return response;
  }

  async tasksResultsFetch(data: TasksResultsFetchParams): Promise<TasksResultsFetchResponse> {
    const method = "POST";
    const url = `${this.domain}/api/v1/tasks/results/fetch`;

    const response = await retry(
      async (bail) => {
        const response = await fetch(url, {
          method,
          headers: { "Content-Type": this.contentType },
          body: JSON.stringify({ ...data, email: this.email, password: this.password }),
        });
        if (!response.ok && 500 <= response.status) {
          const errMessage = await response.text();
          console.error(errMessage);
          throw new Error(this.errorMessage);
        } else if (!response.ok && 400 <= response.status) {
          const errMessageJson = await response.json();
          console.error(errMessageJson);
          const errMessage = errMessageJson.message ? errMessageJson.message : errMessageJson.detail;
          throw new Error(errMessage);
        }

        return await response.json();
      },
      {
        retries: Number(process.env["RESOLVE_RETRY_MAX_TIMES"]),
        onRetry: async (err: Error, num: number) => resolver(err, num),
      }
    );
    return response;
  }
}
