export async function timeSleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getUUID(): string {
  return "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export async function resolver(err: Error, retryTime: number) {
  console.log(`Retry ${retryTime} times. Error: ${err.message}`);
  return await timeSleep(Number(process.env["RESOLVE_BASE_TIME_MS"]) * retryTime ** 2);
}

export async function getEmail(sheet: Excel.Worksheet, context: Excel.RequestContext): Promise<string> {
  const TMP_CELL_ROW_RETRY_MAX = 100;
  const TMP_CELL_COL = "AZ";
  for (let i = 1; i < TMP_CELL_ROW_RETRY_MAX; i++) {
    try {
      const comment = sheet.comments.add(`${TMP_CELL_COL}${i}`, "");
      comment.load("authorEmail");
      await context.sync();
      const email = comment.authorEmail;

      comment.delete();
      await context.sync();
      return email;
    } catch (err) {}
  }

  throw new Error("Emailアドレスの取得に失敗しました");
}
