import retry from "async-retry";
import { resolver } from "./index";
import { Crypto } from "./crypto";

export interface CellPosition {
  row: number;
  column: number;
};

export interface Profile {
  cellPosition: CellPosition;
  paths: string[];
  password: string;
  timeout: number;
}
interface UpdateProfile {
  cellPosition?: CellPosition;
  paths?: string[];
  timeout?: number;
}

export class SecureProfileManager {
  private readonly crypto: Crypto;
  private readonly errorMessage: string;
  constructor() {
    this.crypto = new Crypto();
    this.errorMessage = "OfficeRuntimeのエラーが発生しました.\n再実行お願いします.";
  }

  async saveProfile(profile: Profile, profileKey: string, encryptionKey: string) {
    try {
      await retry(
        async (bail) => {
          const encryptedProfile = this.crypto.encrypt(JSON.stringify(profile), encryptionKey);
          await OfficeRuntime.storage.setItem(profileKey, encryptedProfile);
        },
        {
          retries: Number(process.env["RESOLVE_RETRY_MAX_TIMES"]),
          onRetry: async (err: Error, num: number) => await resolver(err, num),
        }
      );
    } catch (error) {
      console.error(error);
      throw new Error(this.errorMessage);
    }
  }

  async checkProfileKey(profileKey: string): Promise<boolean> {
    try {
      return await retry(
        async (bail) => {
          const profile = await OfficeRuntime.storage.getItem(profileKey);
          return Boolean(profile);
        },
        {
          retries: Number(process.env["RESOLVE_RETRY_MAX_TIMES"]),
          onRetry: async (err: Error, num: number) => await resolver(err, num),
        }
      );
    } catch (error) {
      console.error(error);
      throw new Error(this.errorMessage);
    }
  }

  async getProfile(profileKey: string, encryptionKey: string): Promise<Profile | undefined> {
    try {
      const encryptedProfile = await OfficeRuntime.storage.getItem(profileKey);
      if (!encryptedProfile) return undefined;
      const profile = this.crypto.decrypt(encryptedProfile, encryptionKey);
      return JSON.parse(profile) as Profile;
    } catch (error) {
      console.error(error);
      throw new Error(this.errorMessage);
    }
  }

  async updateProfile(updateProfile: UpdateProfile, profileKey: string, encryptionKey: string) {
    try {
      await retry(
        async (bail) => {
          const currentProfile = await this.getProfile(profileKey, encryptionKey);
          const newProfile = { ...currentProfile, ...updateProfile } as Profile;
          await this.saveProfile(newProfile, profileKey, encryptionKey);
        },
        {
          retries: Number(process.env["RESOLVE_RETRY_MAX_TIMES"]),
          onRetry: async (err: Error, num: number) => await resolver(err, num),
        }
      );
    } catch (error) {
      console.error(error);
      throw new Error(this.errorMessage);
    }
  }

  async deleteProfile(profileKey: string) {
    try {
      await retry(
        async (bail) => {
          await OfficeRuntime.storage.removeItem(profileKey);
        },
        {
          retries: Number(process.env["RESOLVE_RETRY_MAX_TIMES"]),
          onRetry: async (err: Error, num: number) => await resolver(err, num),
        }
      );
    } catch (error) {
      console.error(error);
      throw new Error(this.errorMessage);
    }
  }

  async deleteProfiles(profileKeys: string[]) {
    try {
      await retry(
        async (bail) => {
          await OfficeRuntime.storage.removeItems(profileKeys);
        },
        {
          retries: Number(process.env["RESOLVE_RETRY_MAX_TIMES"]),
          onRetry: async (err: Error, num: number) => await resolver(err, num),
        }
      );
    } catch (error) {
      console.error(error);
      throw new Error(this.errorMessage);
    }
  }
}
