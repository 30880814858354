import * as crypto from "crypto-js";

export class Crypto {
  private readonly errorMessage: string;
  constructor() {
    this.errorMessage = "暗号化/複合化処理に失敗しました.\n再実行お願いします.";
  }

  public encrypt(data: string, encryptionKey: string): string {
    try {
      const encrypted = crypto.AES.encrypt(data, encryptionKey);
      return encrypted.toString();
    } catch (error) {
      console.error(error);
      throw new Error(this.errorMessage);
    }
  }

  public decrypt(encrypted: string, encryptionKey: string): string {
    try {
      const decryptedBytes = crypto.AES.decrypt(encrypted, encryptionKey);
      const decrypted = decryptedBytes.toString(crypto.enc.Utf8);
      return decrypted;
    } catch (error) {
      console.error(error);
      throw new Error(this.errorMessage);
    }
  }
}
