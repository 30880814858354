import {
  TaskExecutionsParams,
  TasksResultsFetchParams,
  TaskExecutionsResponse,
  TasksResultsFetchResponse,
} from "../adyton-edge";

export class AdytonEdgeStub {
  async taskExecutions(data: TaskExecutionsParams): Promise<TaskExecutionsResponse> {
    return {
      executions: [
        {
          id: "testId",
          path: "testPath",
          timeout: 11,
        },
      ],
    };
  }

  async tasksResultsFetch(data: TasksResultsFetchParams): Promise<TasksResultsFetchResponse> {
    return {
      results: [
        {
          path: "testPath",
          is_finished: true,
          content: [
            {
              type: "text",
              text: "testText",
            },
          ],
        },
      ],
    };
  }
}
