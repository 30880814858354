export interface GetGLPGSFuncsType {
  row: number;
  column: number;
  value: string;
}
interface FormatterGLPGSFuncType {
  function: string;
  params: string[];
}

export class GLGPSFuncs {
  private readonly context: Excel.RequestContext;
  private readonly sheet: Excel.Worksheet;
  private readonly prefix: string;

  constructor(context: Excel.RequestContext, sheet: Excel.Worksheet, prefix: string) {
    this.context = context;
    this.sheet = sheet;
    this.prefix = prefix;
  }

  public async get(): Promise<GetGLPGSFuncsType[]> {
    let usedRange = this.sheet.getUsedRangeOrNullObject();
    usedRange.load("rowIndex, columnIndex, rowCount, columnCount");
    await this.context.sync();

    if (usedRange.isNullObject) return [];

    let fullRange = this.sheet.getRangeByIndexes(
      0,
      0,
      usedRange.rowIndex + usedRange.rowCount,
      usedRange.columnIndex + usedRange.columnCount
    );
    fullRange.load("values");
    await this.context.sync();
    const usedValues = fullRange.values;

    const returnValue: GetGLPGSFuncsType[] = [];
    usedValues.forEach((row: string[], rowIndex: number) => {
      row.forEach((value: string, columnIndex: number) => {
        if (typeof value !== "string") return;
        if (value.startsWith(this.prefix) && !usedValues[rowIndex][columnIndex + 1]) {
          returnValue.push({ row: rowIndex, column: columnIndex, value });
        }
      });
    });
    return returnValue;
  }

  public async formatter(value: string): Promise<FormatterGLPGSFuncType> {
    const func = this.__regFunc(value);
    if (!func) return undefined;

    const functionName = this.__getFunction(value);
    const params = await this.__getArgs(func[1]);
    return { function: functionName, params };
  }

  private __regFunc(context: string): RegExpMatchArray {
    const regex = /GLPGS\.\w+\((.*?)\)/;
    const match = context.match(regex);
    return match;
  }

  private __getFunction(context: string): string {
    return context.substring(`${this.prefix}.`.length, context.indexOf("(", 0));
  }

  private async __getArgs(argContext: string): Promise<string[]> {
    const args = argContext.split(",");
    const returnArgs: string[] = [];
    for (const arg of args) {
      const range = this.sheet.getRange(arg.trim());
      range.load("values");
      await this.context.sync();

      let returnArg: string = "";
      for (const rowValues of range.values) {
        returnArg = returnArg + rowValues.join("\n");
      }

      returnArgs.push(returnArg);
    }

    return returnArgs;
  }
}
